<template>
    <v-container>
        <v-row justify="center">
            <h1>{{ $t('general.welcome') }}</h1>
        </v-row>
    </v-container>
</template>

<script type="text/babel">
    export default {}
</script>